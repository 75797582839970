import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Container, Grid, Heading, Text } from 'theme-ui'
import ArticleAuthor from '~/components/Article/Author'
import ArticleRelatedContent from '~/components/Article/RelatedContent'
import ArticleShare from '~/components/Article/Share'
import RichTextRenderer from '~/components/Content/RichText'
import ContentSection from '~/components/Content/Section'
import YoutubeVideo from '~/components/Generic/YoutubeVideo'

const ArticlePage = ({ article }) => {
  const {
    author,
    body,
    category,
    description,
    featuredMediaSize,
    featuredVideo,
    image,
    lowerSections,
    publishDate,
    relatedContent,
    title,
  } = article

  return (
    <Box>
      <Box pt={[5, 7, 9]} pb={[7, 8, 10]}>
        <Container>
          <Grid columns={[1, 12]} sx={{ rowGap: '0' }}>
            <Box sx={{ gridColumn: [null, '2 / 12', null, '3 / 11'] }}>
              {category && (
                <Text as="p" variant="subtitle2" mt={0} mb={[1, 2]}>
                  {category.title}
                </Text>
              )}
              <Heading as="h1" variant="title2" mt={0} mb={[1, 2]}>
                {title}
              </Heading>
              {description && (
                <Box
                  variant="text.body2"
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                  sx={{
                    p: {
                      '&:first-of-type': {
                        mt: 0,
                      },
                      '&:last-of-type': {
                        mb: 0,
                      },
                    },
                  }}
                />
              )}
              {author && (
                <Box mt={[4, 3]}>
                  <ArticleAuthor author={author} date={publishDate} />
                </Box>
              )}
            </Box>

            {(featuredVideo || image) && (
              <Box
                mt={[4, null, 5]}
                mx={[-2, 0]}
                sx={{
                  gridColumn:
                    featuredMediaSize === 'Small'
                      ? [null, '2 / 12', null, '3 / 11']
                      : [null, '1 / -1', null, '2 / 12'],
                }}
              >
                {featuredVideo && <YoutubeVideo id={featuredVideo} />}
                {!featuredVideo && image && (
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.description}
                    loading="eager"
                  />
                )}
              </Box>
            )}

            <Box
              mt={[4, null, 5]}
              sx={{ gridColumn: [null, '2 / 12', null, '3 / 11'] }}
            >
              <ArticleShare title={title} />
            </Box>

            <Box
              mt={[1, 2, null, 3]}
              sx={{
                gridColumn: [null, '2 / 12', null, '3 / 11'],
                'p:last-of-type': { mb: 0 },
              }}
            >
              <RichTextRenderer richText={body} optionSet="body" />
            </Box>
          </Grid>
        </Container>
      </Box>
      {relatedContent && <ArticleRelatedContent articles={relatedContent} />}
      {lowerSections &&
        lowerSections.map((section) => {
          return <ContentSection key={section.id} section={section} />
        })}
    </Box>
  )
}

ArticlePage.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      bio: PropTypes.shape({}),
    }),
  }),
}

export default ArticlePage
