import React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ArticlePage from '~/components/Article/Page'
import StructuredArticleData from '~/components/StructuredData/Article'
import { stripHtml } from '~/utils/formatters'

const ArticleTemplate = ({ data }) => {
  const { article } = data
  const { description, image, title } = article

  return (
    <Layout>
      <Metadata
        title={title}
        description={stripHtml(description.childMarkdownRemark.html)}
        image={getSrc(image)}
      />
      <StructuredArticleData article={article} />
      <ArticlePage article={article} />
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String, $locale: String) {
    article: contentfulArticle(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ArticlePageFragment
    }
  }
`

export default ArticleTemplate
