import React from 'react'
import { Box, Container, Grid, Heading } from 'theme-ui'
import ArticleCard from '~/components/Article/Card'

const ArticleRelatedContent = ({ articles }) => {
  return (
    <Box bg="backgroundMedium" pt={[6, 7, 9]} pb={[7, 8, 10]}>
      <Container>
        <Heading as="h2" variant="title3" mb={[2, 3, 4]}>
          {'Recommended for you'}
        </Heading>
        <Grid columns={[1, 2, 3]}>
          {articles.map((article, index) => (
            <Box key={`article-${index}`}>
              <ArticleCard article={{ ...article, description: null }} />
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default ArticleRelatedContent
