/** @jsx jsx */
import moment from 'moment'
import { getSrc } from 'gatsby-plugin-image'
import { jsx } from 'theme-ui'
import StructuredData from '~/components/StructuredData'

const StructuredArticleData = ({ article }) => {
  const { author, image, publishDateRaw, title } = article

  // @see https://developers.google.com/search/docs/appearance/structured-data/article
  return (
    <StructuredData
      type="Article"
      data={{
        headline: title,
        image: getSrc(image),
        datePublished: moment(publishDateRaw).toISOString(),
        author: [
          {
            '@type': 'Person',
            name: author?.name,
          },
        ],
      }}
    />
  )
}

export default StructuredArticleData
