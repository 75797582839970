/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Flex, jsx, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const Author = ({ author, date }) => {
  const { image, name } = author
  const translate = useTranslate()

  return (
    <Flex sx={{ alignItems: 'center' }}>
      {image && (
        <Box mr={2} sx={{ height: '56px' }}>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.description}
            loading="eager"
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              transform: 'translateZ(0)',
            }}
          />
        </Box>
      )}
      <Box>
        <Text as="p" variant="body3" sx={{ fontWeight: 'bold' }}>
          {translate('articles.author_by')} {name}
        </Text>
        {date && (
          <Text as="p" variant="body3">
            {date}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

export default Author
