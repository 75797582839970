/** @jsx jsx */
import { Box, Flex, jsx, Link, Text } from 'theme-ui'
import { useLocation } from '@reach/router'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import useSiteMetadataQuery from '~/hooks/graphql/queries/use-site-metadata'
import EnvelopeIcon from '~/assets/images/icons/envelope.inline.svg'
import FacebookIcon from '~/assets/images/icons/facebook.inline.svg'
import PinterestIcon from '~/assets/images/icons/pinterest.inline.svg'
import TwitterIcon from '~/assets/images/icons/twitter.inline.svg'

const ShareButton = ({ children, ...props }) => {
  return (
    <Link
      sx={{
        color: 'black',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mr: 2,
        ':last-child': {
          mr: 0,
        },
        '& > svg': {
          height: '28px',
          width: 'auto',
        },
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

const Share = ({ title }) => {
  const { pathname } = useLocation()
  const { site } = useSiteMetadataQuery()
  const translate = useTranslate()

  const url = `${site.siteMetadata.siteUrl}${pathname}`
  const appId = 2433382053575481

  const twitterUrl = encodeURIComponent(
    `${title} by @makerwineco ${url}?utm_campaign=socialshare&utm_source=twitter`
  )
  const twitterHref = `https://twitter.com/intent/tweet?text=${twitterUrl}`

  const facebookUrl = encodeURIComponent(
    `${url}?utm_campaign=socialshare&utm_source=twitter`
  )
  const facebookHref = `https://www.facebook.com/sharer/sharer.php?app_id=${appId}&sdk=joey&u=${facebookUrl}%2F&display=popup&ref=plugin&src=share_button`
  const openFacebook = function (e) {
    window.open(facebookHref, 'popupWindow', 'width=675, height=350')
    e.preventDefault()
    return false
  }

  const pinterestUrl = encodeURIComponent(
    `${url}?utm_campaign=socialshare&utm_source=pinterest`
  )
  const pinterestHref = `http://www.pinterest.com/pin/create/button/?url=${pinterestUrl}&is_video=false`

  const emailUrl = encodeURIComponent(
    `${url}?utm_campaign=socialshare&utm_source=email`
  )
  const emailHref = `mailto:?subject=${title}&body=${emailUrl}`

  return (
    <Box>
      <Text as="p" variant="callout1" mt={0} mb={2}>
        {translate('articles.share')}
      </Text>
      <Flex>
        <ShareButton
          href={twitterHref}
          target="_blank"
          title="Twitter (opens in a new window)"
          rel="noreferrer"
          sx={{
            '& > svg': {
              height: '34px',
            },
          }}
        >
          <TwitterIcon />
        </ShareButton>
        <ShareButton
          href={facebookHref}
          onClick={(e) => openFacebook(e)}
          target="_blank"
          title="Facebook (opens in a new window)"
          rel="noreferrer"
        >
          <FacebookIcon />
        </ShareButton>
        <ShareButton
          href={pinterestHref}
          target="_blank"
          title="Pinterest (opens in a new window)"
          rel="noreferrer"
        >
          <PinterestIcon />
        </ShareButton>
        <ShareButton href={emailHref} aria-label="Email" rel="noreferrer">
          <EnvelopeIcon />
        </ShareButton>
      </Flex>
    </Box>
  )
}

export default Share
